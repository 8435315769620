<template>
<div v-if="!!ores">
    <div class="mainWrp">
        <img src="../assets/rslogo.jpg" alt="人寿公司logo" class="rslogo" />
        <!-- <img src="https://www.visasiv.cn/singlePage/jiao.png" alt="已缴费" class="checkpay"> -->
        <!-- <div class="checkpay">已缴费</div> -->
        <h2>投 保 确 认 单</h2>
        <h5>本公司根据保险条款和投保单位的申请，将签发正式保险单</h5>
        <table class="stf_table" border="0" span="0">
            <thead align="center">
                <tr>
                    <td colspan="4">［ 保 单 资 料 ］</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="4" align="right">币别：人民币元</td>
                </tr>
                <tr>
                    <td style="width: 130px;">投保单号</td>
                    <td colspan="3">{{num}}</td>
                </tr>
                <tr>
                    <td>投保人</td>
                    <td>{{name}}</td>
                    <td>被保险人</td>
                    <td>{{vname}}</td>
                </tr>
                <tr>
                    <td>被保险人证件号</td>
                    <td>{{vcertno}}</td>
                    <td>被保险人数</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>合同保障期</td>
                    <td>{{dateStr}}</td>
                    <td>机构信息</td>
                    <td>{{schoolInfo}}</td>
                </tr>
                <tr>
                    <td>联系方式</td>
                    <td>{{phone}}</td>
                    <td>交费方式</td>
                    <td class="red">趸交</td>
                </tr>
                <tr>
                    <td>受益人</td>
                    <td colspan="3" class="red">身故保险金受益人为被保险人的法定继承人，除身故保险金之外的其他保险金的受益人为被保险人本人。</td>
                </tr>
            </tbody>
        </table>
        <h4>【保险利益及保险费】</h4>
        <img class="insPic" :src="Invoiceimg" alt="投保险种" mode="widthFix" />
        <table class="stf_table info_table" border="0" span="0">
            <thead align="center">
                <tr>
                    <td colspan="6"></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="6">
                        <div v-html="InvoiceContent" style="text-align: left;"></div>
                    </td>
                </tr>
                <tr>
                    <td>投保人及被保人声明</td>
                    <td colspan="5" class="checkInfo">
                        <p>
                            贵公司已对保险合同的条款内容履行了说明义务，并对责任免除条款履行了明确说明义务。投保单位已仔细阅知，理解投保提示及保险条款尤其是责任免除、解除合同等规定，并同意遵守。所填投保单各项及告知事项均属事实并确无欺瞒。上述一切陈述及本声明将成为贵公司承保的依据，并作为保险合同的一部分。如有不实告知，贵公司有权在法定期限内解除合同，并依法决定是否对合同解除前发生的保险事故承担保险责任。投保人可持我公司相关材料，办理我公司保险日常业务。
                        </p>
                        <p> 提示：</p>
                        <p> 1、为便于互联网电子保单查询和下载、接收承保短信通知，请投保人（缴费人）在回执中留下您的有效手机号码和被保险人身份证号码。</p>
                        <p> 2、为便于查询到关于“中国保险行业协会、中国法医学会联合发布《人身保险伤残评定标准》”的资料，请投保人（缴费人）登录网址http://www.iachina.cn查询。
                        </p>
                        <p>投保人声明</p>
                        <p>1、本人已经知晓银保监相关投保的规定，并如实告知。
                        </p>
                        <p> 2、本人对上述保险的保险责任和责任免除内容已经详细阅读、理解；</p>
                        <p> 3、本人同意对被保险人投保上述保险。</p>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6">重要提示：客户可登陆至www.e-chinalife.com查询本公司最新偿付能力信息、保单信息及上述相关条款内容。</td>
                </tr>
            </tfoot>
        </table>
        <!-- <img src="pics/saveBtn.png" alt="save" id="saveBtn"> -->
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            ores: '',
            name: '',
            vname: '',
            vcertno: '',
            money: '',
            num: '',
            InvoiceContent: '',
            Invoiceimg: '',
            supplementName: '',
            cityname: '',
            dateStr: '',
            schoolInfo: '',
            phone: ''
        }
    },

    created() {
        let that = this

        this.wpost({
            url: '/Ajax/Insurance/getinsurancemodel',
            data: {
                id: this.$route.params.id,
            },
            succ: function (data) {
                console.log("data:", data)
                var supplement = data.supplement
                var ores = data.model

                that.ores = data.model
                that.name = ores.Insurance_Name,
                    that.vname = ores.Insurance_VisitName,
                    that.vcertno = ores.Insurance_VisitCertNo,
                    that.money = ores.Insurance_TotalMoney,
                    that.num = ores.Insurance_Number,
                    that.InvoiceContent = supplement.Supplement_InvoiceContent,
                    that.Invoiceimg = this.domin + ores.Insurance_ClauseImage
                that.supplementName = ores.Insurance_SupplementName,
                    that.cityname = ores.Insurance_Area,
                    that.dateStr = ores.Insurance_EffectContent ? ores.Insurance_EffectContent : ores
                    .Insurance_EffectStart.substr(
                        0, 10) +
                    ' —— ' + ores.Insurance_EffectEnd.substr(0, 10),
                    that.schoolInfo = ores.Insurance_School + ' ' + ores.Insurance_SchoolBranch + ' ' +
                    ores.Insurance_Grade + ' ' +
                    ores.Insurance_Classes,
                    that.phone = ores.Insurance_Phone

            }
        })



        this.setMeta()
    },
    methods: {
        setMeta() {
            console.log("setMeta")
            const metas = document.querySelector('head').querySelector('meta[name=viewport]')
            var scaleNum = (window.innerWidth / 900).toFixed(2)
            metas.content = 'width=device-width, initial-scale=' + scaleNum
        },
        
    }
}
</script>

<style scoped>


.mainWrp {
    position: relative;
    width: 900px;
    margin: 0 auto;
    background: #fff;
    padding:20px 0

}

.stf_table {
    border-spacing: 0;
    width: 97%;
    margin: 0 auto;
    line-height: 30px;
}

.stf_table td {
    border: 1px solid #999;
    padding: 5px;
}

.red {
    color: #f00;
}

.insPic {
    display: block;
    width: 97%;
    margin: 12px auto;
}

.mainWrp h2,
.mainWrp h4,
.mainWrp h5 {
    text-align: center;
    margin: 10px auto;
}

.info_table tfoot td {
    font-size: 13px;
    text-align: left;
}

.info_table td {
    text-align: center;
}

.info_table tr td:first-child {
    width: 80px;
}

.info_table td.checkInfo {
    text-align: left
}

td.sptd {
    width: 160px !important;
}

.checkpay {
    position: absolute;
    top: 37%;
    left: 50%;
    width: 21%;
    min-width: 200px;
    color: #ff6347;
    border-radius: 35px;
    height: 70px;
    line-height: 70px;
    font-size: 2.6rem;
    text-align: center;
    border: 5px solid #ff6347;
    max-width: 230px;
    z-index: 1;
    transform: rotateZ(-45deg) translateX(-50%);
}

.rslogo {
    display: block;
    margin: 20px auto;
    width: 41%;
}

#saveBtn {
    /* display: none; */
    position: absolute;
    top: 30px;
    right: 30px;
    height: 50px;
    z-index: 1;
    background-color: #fff;

}
</style>
